import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Kontakt' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Kontaktieren Sie uns!"
          subheader='Nehmen Sie gerne Kontakt mit uns auf und schreiben Sie uns Ihr Feedback.'
        />
        <Divider />
        <ContactForm />
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
