import React from 'react'
import PropTypes from 'prop-types'
import { Box, Label, Input, Textarea, Button, Message, Spinner } from 'theme-ui'
import Recaptcha from 'react-recaptcha'

// create a variable to store the component instance
let recaptchaInstance

// manually trigger reCAPTCHA execution
const executeCaptcha = function () {
  recaptchaInstance.execute()
}

// executed when the Repactcha was loaded successfully
const callback = function () {
  // style the position of the Re-Captcha logo from google
  let el = document.getElementById('g-recaptcha')
  if (el) {
    const mediaQuery = window.matchMedia('(max-width: 500px)')
    // Check if the media query is true
    if (mediaQuery.matches) {
      el.style.marginTop = '2em'
    } else {
      el.style.float = 'right'
    }
  }
}

// executed once the captcha has been verified
// can be used to post forms, redirect, etc.
const verifyCallback = function (response) {
  document.getElementById('contact').submit()
}

const ContactForm = ({ handleSubmit, submitting, success, repcat }) => (
  <form
    onSubmit={executeCaptcha}
    id='contact'
    method='POST'
    action='https://getform.io/f/821c41a5-f579-4cf6-a810-4988a1d9fe63'
  >
    {success === true && (
      <Message variant='success'>
        Vielen Dank für Deine Nachricht. Wir melden uns so schnell wie möglich!
      </Message>
    )}
    {success === false && (
      <Message variant='error'>
        Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal!
      </Message>
    )}
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-name'>Name*</Label>
        <Input
          type='text'
          id='contact-form-name'
          name='name'
          placeholder='Vorname, Nachname'
          required
        />
      </Box>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-email'>Email*</Label>
        <Input
          type='email'
          placeholder='email@example.com'
          id='contact-form-email'
          name='email'
          required
        />
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-subject'>Titel der Anfrage</Label>
      <Input type='text' id='contact-form-subject' name='subject' />
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-message'>Inhalt der Nachricht*</Label>
      <Textarea name='message' id='contact-form-message' required />
    </Box>
    <Button
      variant={success || submitting ? 'disabled' : 'primary'}
      disabled={success || submitting}
      type='submit'
      required
    >
      Senden {submitting && <Spinner size='20' />}
    </Button>
    <Recaptcha
      ref={e => (recaptchaInstance = e)}
      sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
      size='invisible'
      verifyCallback={verifyCallback}
      onloadCallback={callback}
      hl='de'
      badge='inline'
    />
  </form>
)

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
